@import './Custom.scss';
@import './Fonts.scss';
@import '~bootstrap/scss/bootstrap';

body {
    max-width: 1000px;
    margin: auto;
    background-image: url('./img/bg.jpg');
    background-attachment: fixed;
    background-size: cover;
}

#root {
    background-color: #fff;
    border-radius: 0 0 1em 1em;
}

a {
    text-decoration: none;
}

:focus {
    box-shadow: none !important;
}

.dropdown-menu {
    min-width: auto;
}

.text-sm {
    font-size: 0.75rem;
}

.custom-tooltip {
    & > .tooltip-inner {
        max-width: 400px;
    }
}

.form-control + .form-control,
.form-select + .form-control,
.form-control + .form-select,
.form-select + .form-select {
    margin-top: 0.25rem;
}

.modal-content {
    max-height: calc(100vh - 3.5rem);

    .modal-body {
        overflow-y: auto;
    }
}

.gallery {
    width: 100%;

    .thumbnail {
        width: 20%;
        padding: 4px;
        margin: 0;

        img {
            border: 1px solid lightgrey;
            border-radius: 3%;
            width: 100%;
        }
    }
}
